<script lang="ts">
	import UserOneImage from '$images/landingpage/hero/existing-user-avatar-1.png?enhanced';
	import UserTwoImage from '$images/landingpage/hero/existing-user-avatar-2.png?enhanced';
	import UserThreeImage from '$images/landingpage/hero/existing-user-avatar-3.png?enhanced';

	export let numUsers: number;

	const AVATARS = [UserOneImage, UserTwoImage, UserThreeImage];
</script>

<div
	class="flex items-center gap-3 max-tablet:mx-auto max-tablet:flex-col max-tablet:justify-center"
>
	<!-- Avatars section -->
	<div class="flex -space-x-3">
		{#each AVATARS as p (p)}
			<enhanced:img
				alt="Person"
				loading="eager"
				src={p}
				class="size-12 rounded-full border-4 border-surface-400"
			/>
		{/each}
	</div>

	<!-- Existing content -->
	<div class="flex flex-col max-tablet:items-center max-tablet:gap-y-1">
		<!-- Stars section -->
		<div class="flex items-center max-tablet:w-fit">
			<iconify-icon icon="solar:star-bold" height="24" class="text-yellow-500"></iconify-icon>
			<iconify-icon icon="solar:star-bold" height="24" class="text-yellow-500"></iconify-icon>
			<iconify-icon icon="solar:star-bold" height="24" class="text-yellow-500"></iconify-icon>
			<iconify-icon icon="solar:star-bold" height="24" class="text-yellow-500"></iconify-icon>
			<iconify-icon icon="solar:star-bold" height="24" class="text-yellow-500"></iconify-icon>
		</div>

		<span class="font-[500]">
			Loved by {numUsers ?? 100}+ other investors
		</span>
	</div>
</div>
