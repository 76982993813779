<script context="module" lang="ts">
	const FEATURES = [
		{
			heading: 'Advanced Charting',
			icon: 'tabler:chart-line',
			subheading:
				'Analyze stock performance over any time frame with our advanced charting tools.'
		},
		{
			heading: 'World-Class Screener',
			icon: 'solar:target-bold',
			subheading: 'Utilize the most powerful and user-friendly screener on the market.'
		},
		{
			heading: 'Real-Time Price Updates',
			icon: 'solar:bolt-bold',
			subheading:
				'Watch the price of your favorite change without refreshes, thanks to the power of WebSockets.'
		},
		{
			heading: 'Over 20,000 Symbols',
			icon: 'solar:chat-round-money-bold',
			subheading: 'Explore comprehensive real-time market data for more than 20,000 symbols.'
		},
		{
			heading: 'Breaking Market News',
			icon: 'solar:documents-bold',
			subheading: 'Stay up-to-date with a personalized news feed curated just for you.'
		},
		{
			heading: 'Price Alerts',
			icon: 'solar:bell-bold',
			subheading:
				'Set price alerts and get notified by email the minute the instrument crosses its threshold price.'
		},
		{
			heading: 'AI-Powered Insights',
			icon: 'mdi:robot-excited',
			subheading: 'Get instant market summaries, stock performance summaries and more.'
		},
		{
			heading: 'And more coming soon...',
			icon: 'solar:rocket-bold',
			subheading:
				'Tickrr is constantly under development, with new features and updates being added regularly.'
		}
	];
</script>

<script lang="ts">
	import { fadeInOnIntersect } from '@tickrr/ui/actions';

	import SectionHeading from '../common/SectionHeading.svelte';

	export let sectionId: string = 'misc';
	export let areTransitionsEnabled: boolean = true;
</script>

<section
	id={sectionId}
	class="space-y-6"
	use:fadeInOnIntersect={{
		duration: 1250,
		enabled: areTransitionsEnabled,
		translate: 'translate-y-40'
	}}
>
	<SectionHeading
		classes="max-w-3xl"
		linkProps={{ href: '/signup', text: 'Join now, completely free' }}
	>
		<svelte:fragment slot="top-text">Comprehensive trading toolkit</svelte:fragment>
		<svelte:fragment slot="heading">Explore Our Full Feature Set</svelte:fragment>
		<svelte:fragment slot="subheading">
			Discover the breadth of Tickrr's capabilities. From advanced analytics to real-time
			alerts, we offer a suite of tools designed to enhance your trading experience and
			decision-making process.
		</svelte:fragment>
	</SectionHeading>

	<div
		class="grid grid-cols-1 gap-8 tablet:grid-cols-2 tablet:py-8 laptop:grid-cols-3 desktop:grid-cols-4"
	>
		{#each FEATURES as feature (feature)}
			<div
				class="group w-full transition-transform duration-300 ease-in-out rounded-container-token hover:-translate-y-1"
			>
				<div class="mb-4 text-4xl text-surface-50">
					<iconify-icon icon={feature.icon} class="group-hover:animate-shake-icon"
					></iconify-icon>
				</div>
				<h3 class="mb-2 text-xl font-semibold text-surface-50">{feature.heading}</h3>
				<p class="text-sm">{feature.subheading}</p>
			</div>
		{/each}
	</div>
</section>
