<script lang="ts">
	export let text: string;
	export let reviewerName: string;
	export let reviewerOccupation: string;
	export let classes: string = '';
</script>

<div class="mx-auto max-w-screen-xl py-8 text-center {classes}">
	<figure class="mx-auto max-w-screen-md">
		<svg
			fill="none"
			viewBox="0 0 24 27"
			xmlns="http://www.w3.org/2000/svg"
			class="mx-auto mb-3 h-12 text-surface-400"
		>
			<path
				d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
				fill="currentColor"
			/>
		</svg>
		<blockquote>
			<p class="text-2xl font-medium text-surface-50">
				"{text.trim()}"
			</p>
		</blockquote>
		<figcaption class="mt-6 flex items-center justify-center space-x-3">
			<div class="flex items-center divide-x-2 divide-surface-700">
				<div class="pr-3 font-medium text-surface-50">
					{reviewerName}
				</div>
				<div class="pl-3 text-sm font-light text-surface-400">
					{reviewerOccupation}
				</div>
			</div>
		</figcaption>
	</figure>
</div>
