<script lang="ts">
	import { fadeInOnIntersect } from '@tickrr/ui/actions';

	import FAQ from '../common/FAQ.svelte';
	import SectionHeading from '../common/SectionHeading.svelte';

	export let sectionId: string = 'faq';
	export let areTransitionsEnabled: boolean = true;
</script>

<section
	id={sectionId}
	class="space-y-8 tablet:space-y-12"
	use:fadeInOnIntersect={{
		duration: 1250,
		enabled: areTransitionsEnabled,
		translate: 'translate-y-40'
	}}
>
	<SectionHeading>
		<svelte:fragment slot="top-text">Commonly asked questions</svelte:fragment>
		<svelte:fragment slot="heading">FAQ</svelte:fragment>
		<svelte:fragment slot="subheading">
			Find answers to some of our most frequently asked questions below.
		</svelte:fragment>
	</SectionHeading>
	<FAQ />
</section>
