<script lang="ts">
	import BouncyArrow from '@tickrr/ui/components/v2/elements/animated/BouncyArrow.svelte';
	import PulsatingSphere from '@tickrr/ui/components/v2/elements/animated/PulsatingSphere.svelte';

	export let classes: string = '';
	export let linkProps: { href: string; text: string } | null = null;
</script>

<div class="w-full {classes}">
	<div class="mb-2 flex w-fit items-center gap-x-2 text-primary-500">
		<PulsatingSphere size="h-2 w-2" />
		<span class="whitespace-nowrap">
			<slot name="top-text" />
		</span>
	</div>

	<h2 class="text-5xl font-black text-surface-50">
		<slot name="heading" />
	</h2>

	{#if $$slots['subheading']}
		<p class="mt-4 text-xl text-surface-100">
			<slot name="subheading" />
		</p>
	{/if}

	{#if linkProps}
		<a
			href={linkProps.href}
			class="group anchor mt-4 flex w-fit items-center gap-x-2 !text-surface-200 underline hover:!text-surface-50 hover:underline"
		>
			<span>
				{linkProps.text}
			</span>
			<BouncyArrow />
			<!-- <SlidingArrow /> -->
		</a>
	{/if}
</div>
