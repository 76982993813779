<script lang="ts">
	import type { ComponentProps } from 'svelte';

	import { fadeInOnIntersect } from '@tickrr/ui/actions';

	import SectionHeading from '../common/SectionHeading.svelte';
	import Testimonial from '../common/Testimonial.svelte';

	const TESTIMONIALS: ComponentProps<Testimonial>[] = [
		{
			reviewerName: '@mjohnson0',
			reviewerOccupation: 'Financial Analyst',
			text: 'Tickrr has seriously simplified the way I approach researching new stocks. It is easy-to-use, has all the features I need, and the team is very responsive.'
		},
		{
			reviewerName: '@tradezyy',
			reviewerOccupation: 'Day Trader',
			text: 'As a day trader, speed and accuracy are everything. Tickrr has made it much easier to find the information I need. Highly recommended.'
		},
		{
			reviewerName: '@emilybrown99',
			reviewerOccupation: 'Beginner Investor',
			text: 'I’m relatively new to investing, and Tickrr has made it so much easier to understand the market. The platform is user-friendly, and the charts are incredibly helpful!'
		}
	];

	export let sectionId: string = 'testimonials';
	export let areTransitionsEnabled: boolean = true;
</script>

<section
	id={sectionId}
	class="space-y-12"
	use:fadeInOnIntersect={{
		duration: 1250,
		enabled: areTransitionsEnabled,
		translate: 'translate-y-40'
	}}
>
	<SectionHeading
		classes="max-w-sm"
		linkProps={{ href: '/signup', text: 'Join now, completely free' }}
	>
		<svelte:fragment slot="top-text">Loved by all kinds of investors</svelte:fragment>
		<svelte:fragment slot="heading">Testimonials</svelte:fragment>
	</SectionHeading>

	<div class="max-tablet:space-y-12 tablet:grid tablet:grid-cols-2 tablet:gap-6">
		{#each TESTIMONIALS as t, i (t)}
			<Testimonial {...t} classes={i === 2 ? 'tablet:col-span-2' : ''} />
		{/each}
	</div>
</section>
